body {
  overflow: hidden; /* Отключает прокрутку */

  background-image: url('image.JPG');
  background-size: 1000px 1000px;
  background-repeat: no-repeat; 
  background-position: center; /* Center the image */
}


/* Container for the wheel */
.wheelContainer {
  position: relative; /* Positioning context for absolute positioning inside */
  width: 350px; /* Width of the wheel */
  height: 350px; /* Height of the wheel */
  margin: 0 auto; /* Center the container horizontally */
  display: flex;
  justify-content: center; /* Center contents horizontally */
  align-items: center; /* Center contents vertically */
  padding-top: 10%; /* Optional padding if needed */
}

/* Wheel styling */
.wheel {
  width: 100%; /* Take full width of the wheelContainer */
  height: 100%; /* Take full height of the wheelContainer */
  border: 20px solid #9f1402; /* Border color */
  border-radius: 50%; /* Circular border */
  box-shadow: 0 0 30px rgb(0, 0, 0), inset 0 0 30px rgba(0, 0, 0, 0.5);
  transition: filter 0.5s ease-in-out; /* Smooth transition for blur effect */
  z-index: 1; /* Ensure wheel is beneath lightContainer */
}


/* Wheel styling */
.borderLine {
  position: absolute;
  width: 350px; /* Take full width of the wheelContainer */
  height: 350px; /* Take full height of the wheelContainer */
  border: 2px solid #fff200; /* Border color */
  border-radius: 50%; /* Circular border */
  box-shadow: 0 0 30px rgb(0, 0, 0), inset 0 0 30px rgba(0, 0, 0, 0.5);
  transition: filter 0.5s ease-in-out; /* Smooth transition for blur effect */
  z-index: 2; /* Ensure wheel is beneath lightContainer */
}











.wheel-center {
  position: absolute;
  width: 70px; /* Уменьшенный размер */
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2; /* Ensure wheel is beneath lightContainer */
}

.center-circle {
  position: relative;
  width: 60px; /* Уменьшенный размер */
  height: 60px;
  border-radius: 50%;
  /* Оранжевый градиент с эффектом золота */
  background: radial-gradient(circle at 50% 50%, #ff9c00 0%, #ff8c00 40%, #ff7f00 60%, #cc6a00 100%);
  /* Золотая обводка */
  border: 3px solid rgba(255, 215, 0, 0.8);
  box-shadow: 
    0 0 10px rgba(255, 215, 0, 0.6), /* Внешнее свечение */
    inset 0 0 12px rgba(255, 255, 255, 0.6), /* Внутреннее свечение */
    0 5px 10px rgba(0, 0, 0, 0.3); /* Тень */
  overflow: hidden;
}

/* Треугольные световые сегменты */
.center-circle::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: conic-gradient(
    from 0deg,
    rgba(255, 255, 255, 0.4),
    rgba(0, 0, 0, 0.2) 30deg,
    rgba(255, 255, 255, 0.4) 60deg,
    rgba(0, 0, 0, 0.2) 90deg
  );
  clip-path: circle(50%);
  filter: blur(0.5px);
}

/* Эффект металлического отражения */
.center-circle::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: repeating-conic-gradient(
    from 0deg,
    transparent 0deg 45deg,
    rgba(255, 255, 255, 0.3) 45deg 90deg
  );
  clip-path: circle(50%);
  opacity: 0.5;
}

/* Оранжевое свечение в центре */
.center-glow {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: radial-gradient(circle at center, rgba(255, 165, 0, 0.5), transparent);
  top: 10px;
  left: 10px;
  box-shadow: 0 0 10px rgba(255, 165, 0, 0.3);
}

/* Отражение света на выпуклой поверхности */
.center-reflection {
  position: absolute;
  top: 8px; /* Отрегулированная позиция */
  left: 10px;
  width: 20px;
  height: 20px;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.8), transparent);
  border-radius: 50%;
  filter: blur(2px);
  opacity: 0.6;
}

/* Верхний блик */
.center-highlight {
  position: absolute;
  width: 50px;
  height: 25px;
  top: 5px;
  left: 5px;
  border-radius: 50% / 40%;
  background: radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 0.7), transparent);
  filter: blur(1px);
  opacity: 0.8;
}

/* Внутренняя тень */
.inner-shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: radial-gradient(circle at center, rgba(255, 255, 255, 0.2), transparent 60%);
  box-shadow: inset 0 0 12px rgba(0, 0, 0, 0.5);
}
















/* Marker styling */
.marker {
  position: absolute;
  top: 10%; /* Adjust based on your needs */
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5; /* Ensure marker is on top of wheel */
  width: 15%;
}

/* Center logo styling */
.centerLogo {
  position: absolute;
  z-index: 10; /* Ensure logo is on top of wheel */
  border-radius: 50%; /* Make the logo a circle */
  width: 25%; /* Set the size of the logo */
  height: 25%; /* Set the size of the logo */
  overflow: hidden; /* Hide any overflow outside the circle */
}

.centerLogo img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image fills the circle */
  object-position: center; /* Focus on the center of the image */
  border-radius: 50%; /* Apply the circular shape to the image itself */
}

/* Light container styling */
.lightContainer {
  position: absolute; /* Positioned on top of the wheel */
  width: 440px;
  height: 440px; 
  pointer-events: none; /* Optional: prevent interactions with the lightContainer */
  z-index: 3; /* Ensure lightContainer is on top of the wheel */
}
/* Button container (positioned at the bottom center of the wheel) */

.lightSpinButtonContainer {
  position: absolute;
  width: 100%;
  height: 100%;
}


.container {
  position: absolute;
  bottom: -130px;
  display: flex;
  justify-content: center; /* Центрирование по горизонтали */
  align-items: center; /* Центрирование по вертикали */
  width: 100%; /* Занимает всю ширину родителя */
}

.spinButtonBack {
  position: absolute; /* Чтобы лампочки располагались относительно этого элемента */
  background: linear-gradient(90deg, #db262d, #70272e);
  width: 240px; 
  height: 110px; 
  border: 1px solid #e8beae;
  border-radius: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1;
  /* overflow: hidden; */
}


.PrizeButtonBack {
  position: absolute; /* Чтобы лампочки располагались относительно этого элемента */
  background: linear-gradient(90deg, #8b000e, #ff8512);
  width: 240px; 
  height: 110px; 
  border: 1px solid #e8beae;
  border-radius: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1;
  /* overflow: hidden; */
}
.spin-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #70272e, #db262d);
  color: white;

  width: 200px; /* Увеличенный размер */
  height: 80px; /* Увеличенный размер */
  font-size: 50px;
  font-family: 'Times New Roman', Times, serif;
  padding: 10px 20px;
  border: 1px solid white;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 2; /* Кнопка над фоном */
}


  .button-text {
    margin-left: 10px;
    z-index: 1;
  }
  

  .display {
    position: relative;
    /* bottom: -150; */
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(90deg, #ffdf12, #8b000e);
    color: white;
  
    width: 200px; /* Увеличенный размер*/
    height: 80px; /* Увеличенный размер */
    font-size: 50px;
    font-family: 'Times New Roman', Times, serif;
    /* padding: 10px 20px; */
    left: 20px;
    top: 14px;
    border: 1px solid white;
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 2; /* Кнопка над фоном */
}
  
  .display span {
    display: block;
    margin: 5px 0;
  }
  
  .display .prize-text {
    font-size: 20px; /* Увеличим размер текста для более выразительного вида */
    font-weight: 600; /* Сделаем текст немного жирнее */
    color: #000000; /* Изменим цвет на более яркий */
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2); /* Добавим легкую тень для объема */
  }
  
  .display span:first-child {
    font-size: 2rem; /* Увеличим размер шрифта для первого span */
    color: #ccc; /* Цвет текста для первого span */
    text-align: center;
    -webkit-text-stroke-color: #000000;
    font-family: 'Arial Narrow Bold', sans-serif;
    -webkit-text-stroke-width: 2px;
    /* -webkit-text-stroke-color: #000; */
  }
  
  /* .blur { */
    /* animation: blur 0.5s; */
  /* } */
  
  /* @keyframes blur {
    0% {
      filter: blur(1.5px);
    }
    80% {
      filter: blur(1.5px);
    }
    100% {
      filter: blur(0px);
    }
  }
   */



   .close-message {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100px; /* Высота контейнера */
    margin: 10px auto;
    border-radius: 12px; /* Скругление углов */
    background: linear-gradient(135deg, #f0f0f0, #e0e0e0); /* Градиентный фон */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Легкая тень */
    font-family: 'Roboto', sans-serif; /* Современный шрифт */
    color: #888; /* Серый цвет текста */
    text-align: center;
    padding: 10px;
    position: relative;
    border: 1px solid #ccc; /* Легкий бордер */
  }
  
  .close-message h1 {
    font-size: 2rem; /* Размер шрифта заголовка */
    font-weight: 600; /* Жирный текст */
    color: #ff6f61; /* Яркий цвет текста */
    margin: 0;
    animation: pulse 1.5s infinite; /* Пульсирующая анимация */
  }
  
  @keyframes pulse {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
  }